import { render, staticRenderFns } from "./index.vue?vue&type=template&id=54fa4a2a&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IndexHero: require('/app/components/index/IndexHero.vue').default,IndexAbout: require('/app/components/index/IndexAbout.vue').default,IndexAwaits: require('/app/components/index/IndexAwaits.vue').default,IndexParticipation: require('/app/components/index/IndexParticipation.vue').default,IndexNews: require('/app/components/index/IndexNews.vue').default,IndexFaq: require('/app/components/index/IndexFaq.vue').default})
