
import Vue from "vue"
import type { FaqItem } from "@/types/api/faq"

export default Vue.extend({
  data: () => ({
    items: [] as FaqItem[],
  }),

  async fetch () {
    await this.fetchItems()
  },

  computed: {
    collapseItems () {
      return this.items.filter(i => i.question && i.answer).map(i => ({
        title: i.question,
        content: this.$fmt.md(i.answer),
      }))
    },
  },

  methods: {
    async fetchItems () {
      const [res, err] = await this.$api.faq.getFaq({
        params: {
          // "paginator[limit]": 10,
        },
      })
      if (err) console.error(err)
      else this.items = res || []
      // else this.items = (res || []).slice(0, 10)
    },
  },
})
