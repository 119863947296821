// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oZHF9W0q{color:#000;padding-bottom:max(2.11rem,min(3.13vw,3.75rem));padding-top:max(1.83rem,min(2.71vw,3.25rem))}.oZHF9W0q .rblaRIFy{color:#fff;font-size:max(1rem,min(.94vw,1.13rem));margin:max(1.13rem,min(1.67vw,2rem)) auto 0}.vAenIhO\\+{--cols:2;grid-gap:max(1.13rem,min(1.67vw,2rem));display:grid;gap:max(1.13rem,min(1.67vw,2rem));grid-template-columns:repeat(2,1fr);grid-template-columns:repeat(var(--cols),1fr)}@media(max-width:667.98px){.vAenIhO\\+{--cols:1}}._18T3BqJF{aspect-ratio:640/416;border-radius:1em;display:flex;flex-direction:column;font-size:max(1.13rem,min(1.67vw,2rem));justify-content:flex-end;max-width:100%;min-height:13em;overflow:hidden;padding:1.25em;position:relative}._18T3BqJF:nth-child(3n+1){--gradient-color:var(--color-bg)}._60wIrmQJ{height:100%;-o-object-fit:cover;object-fit:cover;width:100%}._60wIrmQJ,.YMEgL86z{bottom:0;left:0;position:absolute;right:0;top:0}.YMEgL86z{background:linear-gradient(0deg,var(--gradient-color,var(--color-primary)) 0,hsla(0,0%,100%,0) 100%);pointer-events:none}.EtQkMuv\\+{word-wrap:break-word;color:#fff;font-weight:900;line-height:normal;position:relative;text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "oZHF9W0q",
	"btn": "rblaRIFy",
	"events": "vAenIhO+",
	"event": "_18T3BqJF",
	"eventCover": "_60wIrmQJ",
	"eventGradient": "YMEgL86z",
	"eventTitle": "EtQkMuv+"
};
module.exports = ___CSS_LOADER_EXPORT___;
