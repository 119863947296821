
import Vue, { toRaw } from "vue"
import { mapState } from "vuex"

export default Vue.extend({
  data: () => ({
    events: toRaw([
      {
        title: "интерактивное шоу <br> в&nbsp;Павильоне &#8470;&nbsp;1",
        cover: "show",
      },
      {
        title: "Образовательная <br> программа",
        cover: "edu",
      },
      {
        title: "Культурно-<br> развлекательная <br> программа",
        cover: "culture",
      },
      {
        title: "Общение с&nbsp;единомышленниками и&nbsp;интересными <br> спикерами",
        cover: "communication",
      },
    ]),
  }),

  computed: {
    ...mapState("app", ["isRegClosed"]),
  },
})
