
import Vue, { toRaw } from "vue"
import { mapState } from "vuex"

export default Vue.extend({
  data: () => ({
    variants: toRaw([
      {
        text: "гражданин&nbsp;РФ в&nbsp;возрасте<br>от&nbsp;7&nbsp;до&nbsp;17&nbsp;лет<br>включительно<br><br>для статусов &laquo;Студент ССУЗа&raquo; и&nbsp;&laquo;Ученик&raquo;",
        img: "boy",
      },
      {
        text: "Гражданин РФ&nbsp;старше 18&nbsp;лет, сопровождающий детскую организованную группу<br><br>для статусов &laquo;Взрослый&raquo; и&nbsp;&laquo;Педагог&raquo;",
        img: "girl",
      },
    ]),
  }),

  computed: {
    ...mapState("app", ["isRegClosed"]),
  },
})
