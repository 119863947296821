// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P\\+Q-C\\+e8{background:var(--color-bg);border-radius:0 0 max(2.11rem,min(3.13vw,3.75rem)) max(2.11rem,min(3.13vw,3.75rem));margin-bottom:max(1.83rem,min(2.71vw,3.25rem));overflow:hidden;padding:max(1.44rem,min(2.14vw,2.56rem)) 0 max(3.13rem,min(7.08vw,8.5rem))}.P\\+Q-C\\+e8 ._9mraRg\\+O{flex-wrap:nowrap}.vOFgcOBQ{padding:0 max(0rem,min(4.79vw,5.75rem));position:relative}@media(max-width:667.98px){.vOFgcOBQ{padding:0}}.vOFgcOBQ>*{position:relative}.v7OFfvvo{bottom:max(0rem,min(3.44vw,4.13rem));left:50%;max-height:100%;-o-object-fit:contain;object-fit:contain;-o-object-position:bottom;object-position:bottom;position:absolute;transform:translateX(-50%);width:80%}.CHbhTeL5{background:rgba(0,0,0,.2);border-radius:max(1.13rem,min(1.67vw,2rem));display:flex;margin-bottom:max(2.5rem,min(3.59vw,4.31rem));overflow:hidden;padding:max(1.13rem,min(1.67vw,2rem))}.CHbhTeL5>*+*{margin-left:max(2.81rem,min(4.17vw,5rem))}@media(max-width:667.98px){.CHbhTeL5{display:block}.CHbhTeL5>*+*{margin-left:0;margin-top:max(1.41rem,min(2.08vw,2.5rem))}}.qBp\\+Qo5-{word-wrap:break-word;font-size:max(1rem,min(1.04vw,1.25rem));font-weight:900;line-height:1.2;text-transform:uppercase}.qBp\\+Qo5- mark{background:none;color:#8ff6e4}.XvW4AJad{display:flex;margin-bottom:max(2.5rem,min(3.59vw,4.31rem))}.XvW4AJad>*+*{margin-left:max(3.38rem,min(5vw,6rem))}@media(max-width:947.98px){.XvW4AJad{display:block}.XvW4AJad>*+*{margin-left:0;margin-top:max(1.13rem,min(1.67vw,2rem))}}.w85\\+7sz\\+{font-size:max(1.5rem,min(1.67vw,2rem));font-weight:900;line-height:normal;text-transform:uppercase}.OJGnZbed{font-size:max(1rem,min(.94vw,1.13rem));line-height:1.4}.OJGnZbed>*+*{margin-top:1.33em}.TWJ-7IYx{font-size:max(1.5rem,min(3.13vw,3.75rem));font-weight:900;line-height:95.9%;text-transform:uppercase}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "P+Q-C+e8",
	"introLogos": "_9mraRg+O",
	"inner": "vOFgcOBQ",
	"bg": "v7OFfvvo",
	"intro": "CHbhTeL5",
	"introText": "qBp+Qo5-",
	"about": "XvW4AJad",
	"aboutHeading": "w85+7sz+",
	"aboutText": "OJGnZbed",
	"heading": "TWJ-7IYx"
};
module.exports = ___CSS_LOADER_EXPORT___;
