// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kqTf6jVq{word-wrap:anywhere;border:none}.kqTf6jVq .el-collapse-item{border-radius:var(--border-radius);box-shadow:0 .25rem 2.94rem rgba(var(--color-shadow-rgb),.12);overflow:hidden}.kqTf6jVq .el-collapse-item:not(:last-child){margin-bottom:var(--spacing-sm)}@media(max-width:667.98px){.kqTf6jVq .el-collapse-item:not(:last-child){margin-bottom:var(--spacing-xs)}}.kqTf6jVq .el-collapse-item__header{color:currentColor!important}.kqTf6jVq .el-collapse-item__header{background-color:var(--color-bg);border-bottom:none;font-size:1.25rem;font-weight:600;height:auto;letter-spacing:.15px;line-height:1.5;padding:var(--spacing-sm) var(--spacing-md)}@media(max-width:667.98px){.kqTf6jVq .el-collapse-item__header{font-size:1rem;padding:var(--spacing-xs) var(--spacing-sm)}}.kqTf6jVq .el-collapse-item__arrow{color:var(--color-fg);font-size:inherit;font-weight:600;margin-right:0;padding-left:var(--spacing-sm)}.kqTf6jVq .el-collapse-item__arrow:before{content:\"\"}.kqTf6jVq .el-collapse-item__arrow.is-active{transform:none}.kqTf6jVq .el-collapse-item__arrow.is-active:before{content:\"\"}.kqTf6jVq .el-collapse-item__wrap{background-color:var(--color-bg);border-bottom:none}.kqTf6jVq .el-collapse-item__content{color:var(--color-gray-4);font-size:1rem;line-height:1.62;max-width:85%;padding:var(--spacing-xs) var(--spacing-md) var(--spacing-md)}@media(max-width:667.98px){.kqTf6jVq .el-collapse-item__content{font-size:.88rem;line-height:1.57;max-width:100%;padding:var(--spacing-default) var(--spacing-sm) var(--spacing-sm)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"collapse": "kqTf6jVq"
};
module.exports = ___CSS_LOADER_EXPORT___;
