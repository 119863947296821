// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CeBwliOj{align-items:center;display:flex;font-size:max(1.27rem,min(1.88vw,2.25rem));justify-content:space-between;margin-bottom:max(2rem,min(3.75vw,4.5rem))}.CeBwliOj>*+*{margin-left:max(1rem,min(3.23vw,3.88rem))}.CeBwliOj .ndStBR5r{height:1.25em}.aBCVYA-N{flex-shrink:0;line-height:normal;text-transform:uppercase}.rZiT4x\\+M{align-items:center;display:flex;flex-grow:1;position:relative}.slo-MAJh{background:#c4c8d4;flex-grow:1;height:max(.06rem,min(.1vw,.13rem))}.rZiT4x\\+M .zfRsSStJ{height:.75em;position:absolute;right:0;top:50%;transform:translateY(-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "CeBwliOj",
	"logo": "ndStBR5r",
	"title": "aBCVYA-N",
	"arrow": "rZiT4x+M",
	"arrowLine": "slo-MAJh",
	"arrowTick": "zfRsSStJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
