
import Vue from "vue"
import type { SwiperOptions } from "swiper"
import type { NewsItem } from "@/types/api/news"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default Vue.extend({
  data: () => ({
    news: [] as NewsItem[],
  }),

  async fetch () {
    await this.fetchNews()
  },

  computed: {
    swiperOptions (): SwiperOptions {
      return {
        spaceBetween: 24,
        slidesPerView: "auto",
        navigation: {
          prevEl: `.${this.$style.swiperBtnPrev}`,
          nextEl: `.${this.$style.swiperBtnNext}`,
          disabledClass: this.$style.swiperBtnDisabled,
        },
        breakpoints: {
          [breakpoints.md]: {
            spaceBetween: 32,
          },
        },
      }
    },
  },

  methods: {
    async fetchNews () {
      const [res, err] = await this.$api.news.getNews({
        params: {
          // "paginator[limit]": 10
        },
      })
      if (err) return console.error(err)
      this.news = this.$api.helpers.getList(res)
    },
  },
})
