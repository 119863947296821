// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EoD9Abh1{background:var(--color-bg)}@media(max-width:667.98px){.EoD9Abh1 ._9jRhFOB5{justify-content:center}}.T3aQB7uE{background:var(--color-primary);border-radius:max(4.22rem,min(6.25vw,7.5rem));margin-bottom:max(1.44rem,min(2.14vw,2.56rem));overflow:hidden;padding:max(3rem,min(4.06vw,4.88rem)) max(1.5rem,min(4.95vw,5.94rem)) max(3rem,min(2.97vw,3.56rem))}.T3aQB7uE,.T3aQB7uE>*{position:relative}.H2UyfiuJ{-o-object-fit:cover;object-fit:cover}@media(max-width:667.98px){.cSYvJk10{display:none}}.H2UyfiuJ,.cSYvJk10{height:100%;position:absolute;right:0;top:0}@media(max-width:947.98px){.H2UyfiuJ,.cSYvJk10{opacity:.1}}._8sqQSxF1{font-size:max(1rem,min(1.04vw,1.25rem));font-weight:900;line-height:95.9%;text-transform:uppercase}.apml2rTK{align-items:center;display:flex;flex-wrap:wrap;margin-bottom:1.9em}.apml2rTK span{white-space:nowrap}.Nwb47iZn{background:currentColor;flex-shrink:0;height:.2em;margin:0 .65em;width:3.6em}.MCY0dT9h{font-size:max(1.5rem,min(2.92vw,3.5rem));font-weight:900;line-height:95.9%;margin-bottom:.4em;text-transform:uppercase}.f5bnELSx{font-size:max(.88rem,min(.83vw,1rem));line-height:1.4;margin-bottom:2.4em}.wqy9FV4y{display:flex;font-size:max(.88rem,min(.94vw,1.13rem));margin-bottom:max(1.34rem,min(1.98vw,2.38rem))}.wqy9FV4y>*+*{margin-left:max(.56rem,min(.83vw,1rem))}@media(max-width:667.98px){.wqy9FV4y{display:block}.wqy9FV4y>*+*{margin-left:0;margin-top:max(.56rem,min(.83vw,1rem))}}.rN1gC-ak{margin-bottom:max(2rem,min(5.21vw,6.25rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "EoD9Abh1",
	"logos": "_9jRhFOB5",
	"content": "T3aQB7uE",
	"bg": "H2UyfiuJ",
	"curve": "cSYvJk10",
	"subheading": "_8sqQSxF1",
	"date": "apml2rTK",
	"dateDash": "Nwb47iZn",
	"heading": "MCY0dT9h",
	"place": "f5bnELSx",
	"btns": "wqy9FV4y",
	"participation": "rN1gC-ak"
};
module.exports = ___CSS_LOADER_EXPORT___;
