// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tkBv7q8n{color:#000;padding:max(1.25rem,min(3.13vw,3.75rem)) 0 max(2.5rem,min(6.25vw,7.5rem))}.IMjxacbi .swiper-container{--x:var(--p-x);--y:calc((100vw - var(--content-width))/2 + var(--p-x));padding:0 max(var(--x),var(--y))}._1rYFjuM1{flex-basis:max(14.63rem,min(21.67vw,26rem));height:auto!important}.MU5Hd5ja{align-items:center;display:flex;flex-shrink:0}.MU5Hd5ja>*+*{margin-left:max(.81rem,min(1.2vw,1.44rem))}._82lwUpdk{align-items:center;border:2px solid;border-radius:9999px;color:var(--color-bg);display:flex;flex-shrink:0;height:2.25rem;justify-content:center;overflow:hidden;transition:.1s linear;transition-property:background,opacity;width:2.25rem}@media(hover:hover){._82lwUpdk:not(.WPJwzYhW):hover{background:rgba(33,79,226,.1)}}@media(hover:none){._82lwUpdk:not(.WPJwzYhW):active{background:rgba(33,79,226,.1)}}.siLR4dr1{transform:none}.lRf6ECT3{transform:rotate(180deg)}._5BdPzzoA{opacity:.3;pointer-events:none}.QaCtcHO3{--size:1.5rem}.t5bALCZP{display:block;height:100%}@media(hover:hover){.t5bALCZP:hover .risLZp8b{-webkit-text-decoration:underline;text-decoration:underline}}@media(hover:none){.t5bALCZP:active .risLZp8b{-webkit-text-decoration:underline;text-decoration:underline}}.UTuMlI1w{margin-bottom:max(1.13rem,min(1.67vw,2rem));overflow:hidden;padding-top:63%;position:relative}.UTuMlI1w>img{border-radius:max(1.13rem,min(1.67vw,2rem));height:100%;left:0;-o-object-fit:cover;object-fit:cover;position:absolute;top:0;width:100%}.risLZp8b{word-wrap:break-word;-webkit-box-orient:vertical;-webkit-line-clamp:3;display:-webkit-box;font-size:max(1rem,min(.94vw,1.13rem));line-height:1.4;margin-bottom:max(1.13rem,min(1.67vw,2rem));overflow:hidden;text-overflow:ellipsis}.sRRbxNQ8{font-size:max(.88rem,min(.83vw,1rem));line-height:1.4;opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "tkBv7q8n",
	"swiper": "IMjxacbi",
	"swiperSlide": "_1rYFjuM1",
	"swiperBtns": "MU5Hd5ja",
	"swiperBtn": "_82lwUpdk",
	"swiperBtnDisabled": "WPJwzYhW",
	"swiperBtnPrev": "siLR4dr1",
	"swiperBtnNext": "lRf6ECT3",
	"swiperBtnDisabled": "_5BdPzzoA",
	"swiperBtnIcon": "QaCtcHO3",
	"card": "t5bALCZP",
	"cardTitle": "risLZp8b",
	"cardImgWrapper": "UTuMlI1w",
	"cardDate": "sRRbxNQ8"
};
module.exports = ___CSS_LOADER_EXPORT___;
