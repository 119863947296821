// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jENkiYH-{background:var(--color-bg);border-radius:max(2.11rem,min(3.13vw,3.75rem)) max(2.11rem,min(3.13vw,3.75rem)) 0 0;padding:max(2.5rem,min(5.1vw,6.13rem)) 0 max(1.25rem,min(2.5vw,3rem))}.mPeA4kDq .el-collapse-item{box-shadow:none!important;margin-bottom:max(.91rem,min(1.35vw,1.63rem))!important}.mPeA4kDq .el-collapse-item{border:1px solid #fff;border-radius:max(1.13rem,min(1.67vw,2rem))}.mPeA4kDq .el-collapse-item__header{font-size:max(1rem,min(1.04vw,1.25rem))!important;font-weight:900!important;line-height:1.2!important;padding:max(.84rem,min(1.25vw,1.5rem))!important}.mPeA4kDq .el-collapse-item__header{text-transform:uppercase;transition:.1s linear;transition-property:background,color}.mPeA4kDq .el-collapse-item__arrow{color:currentColor!important}.mPeA4kDq .el-collapse-item__arrow{transition:.1s linear;transition-property:color}.mPeA4kDq .el-collapse-item__wrap{transition:.1s linear;transition-property:all}.mPeA4kDq .el-collapse-item__content{font-size:max(1rem,min(.94vw,1.13rem))!important;line-height:1.4!important;padding:0 max(.84rem,min(1.25vw,1.5rem)) max(.84rem,min(1.25vw,1.5rem))!important}.mPeA4kDq .el-collapse-item__content{transition:.1s linear;transition-property:color}.mPeA4kDq .el-collapse-item.is-active .el-collapse-item__content,.mPeA4kDq .el-collapse-item.is-active .el-collapse-item__header{color:#000!important}.mPeA4kDq .el-collapse-item.is-active .el-collapse-item__header,.mPeA4kDq .el-collapse-item.is-active .el-collapse-item__wrap{background:#fff!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "jENkiYH-",
	"collapse": "mPeA4kDq"
};
module.exports = ___CSS_LOADER_EXPORT___;
